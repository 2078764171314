<script>
import DatePicker from "@vuepic/vue-datepicker"
import PartnerBadge from '../badge-level'

export default {
  components: {
    DatePicker,
    PartnerBadge
  },
  props: {

  },
  data() {
    return {
      search: {
        groupKey: '',
        searchType: 'username',
        searchText: '',
        beginDate: '',
        endDate: '',
        action: '',
        result: ''
      },
      sort: { createdAt: -1 },
      list: [],
      limit: 50,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1 // 현재 페이지
    }
  },
  created() {
    this.state = this.$route.params.state
  },
  mounted() {
    let beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 1)
    beginDate.setHours(0)
    beginDate.setMinutes(0)
    beginDate.setSeconds(0)

    // let endDate = new Date()

    this.search.beginDate = beginDate
    // this.search.endDate = endDate

    const a = localStorage.getItem('limit')
    this.limit = (a !== 'NaN' && a) ? parseInt(a) : 50
    this.searchList().then()
  },
  methods: {
    setSort: function(field) {
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort = {}
      this.sort[field] = dir
      this.searchList().then()
    },
    goFirst() {
      this.page = 1
    },
    goPre() {
      if (this.page === 1) {
        this.page = 1
      } else {
        this.page -= 1
      }
    },
    goNext() {
      this.page += 1
    },
    searchByPage: function(page) {
      this.page = page
    },
    searchList: async function() {
      const loader = this.$loading.show({ container: this.$refs.contentWrapper})
      try {
        localStorage.setItem('limit', this.limit)
        const params = {
          search: { action: this.search.action, result: this.search.result },
          sdate: this.search.beginDate,
          edate: this.search.endDate,
          sort: this.sort,
          limit: this.limit,
          page: this.page
        }
        params.search[this.search.searchType] = this.search.searchText
        params.search.state = this.state

        const result = await this.$API.log.seamless(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []
        for(let key in result.documents) {
          let doc = result.documents[key]
          doc.showDetail = false
          this.list.push(doc)
        }

        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    openDeposit: function(id) {
      this.$refs['modal-deposit'].open(id)
    },
    openWithdraw: function(id) {
      this.$refs['modal-withdraw'].open(id)
    }
  },
  watch: {
    page: function() {
      this.searchList().then()
    }
  }

}
</script>

<template>
  <div ref="contentWrapper">
    <form class="header-search" @submit.prevent="searchList">
      <div class="row">
        <div class="col-md-2 form-group mb-2">
          <DatePicker
              v-model="search.beginDate"
              :format="'yyyy-MM-dd HH:mm:ss'"
              utc
              auto-apply
              :close-on-auto-apply="false"
              :placeholder="$t('시작일')"
          ></DatePicker>
        </div>

        <div class="col-md-2 form-group mb-3">
          <DatePicker
              v-model="search.endDate"
              :format="'yyyy-MM-dd HH:mm:ss'"
              utc
              auto-apply
              :close-on-auto-apply="false"
              :placeholder="$t('종료일')"
          ></datepicker>
        </div>
        <div class="form-group col-md-1 mb-2">
          <select class="form-control form-select" v-model="search.action" :placeholder="$t('액션')">
            <option value="">::{{ $t('형식') }}::</option>
            <option value="bet">{{ $t('배팅') }}</option>
            <option value="result">{{ $t('배팅결과') }}</option>
            <option value="cancel">{{ $t('취소') }}</option>
            <option value="balance">{{ $t('잔액') }}</option>
          </select>
        </div>
        <div class="form-group col-md-1 mb-2">
          <select class="form-control form-select" v-model="search.searchType">
            <option value="username">::{{ $t('아이디') }}::</option>
            <option value="siteUsername">{{ $t('사이트 아이디') }}</option>
            <option value="returnCode">{{ $t('오류 코드') }}</option>
          </select>
        </div>
        <div class="form-group col-md-1 mb-2">
          <input type="text" class="form-control" v-model="search.searchText" :placeholder="$t('검색어')" />
        </div>
        <div class="col-md-1 mb-1">
          <select class="form-select" v-model="limit" @change="searchList">
            <option :value="10">10개</option>
            <option :value="25">25개</option>
            <option :value="50">50개</option>
            <option :value="100">100개</option>
          </select>
        </div>
        <div class="form-group col-md-3 mb-2">
          <div class="input-group">
            <button type="button" class="btn btn-outline-success" @click="goFirst()">처음</button>
            <button type="button" class="btn btn-outline-primary" @click="goPre()">이전</button>
            <input type="number" class="form-control" min="1" v-model="page" />
            <button type="button" class="btn btn-outline-info" @click="goNext()">다음</button>
          </div>
        </div>
        <div class="col-md-1 mb-2">
          <button type="submit" class="btn btn-dark w-100">검색</button>
        </div>
      </div>
    </form>

    <hr class="mt-0 mb-3" />

    <div id="table-wrapper" class="table-responsive mb-0 rounded-3" style="overflow: auto">
      <table class="table align-middle table-sm table-bordered table-nowrap sticky-table">
        <thead class="table-dark">
        <tr>
          <th>#</th>
          <th>{{ $t('코드') }}</th>
          <th>{{ $t('회원정보') }}</th>
          <th>{{ $t('상위정보') }}</th>
          <th>{{ $t('오류코드') }}</th>
          <th>{{ $t('액션') }}</th>
          <th>{{ $t('결과') }}</th>
          <th>{{ $t('메모') }}</th>
          <th>{{ $t('요청일시') }}</th>
          <th>{{ $t('응답일시') }}</th>
          <th>{{ $t('경과시간') }}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="record in list" :key="record._id">
          <tr>
            <td>
              <button type="button" class="btn btn-sm btn-outline-secondary" @click="record.showDetail = !record.showDetail"><span class="fa fa-plus"></span></button>
            </td>
            <td>{{ record._id }}</td>
            <td>
              <div v-if="record.user"><router-link :to="'/user/detail/' + record.user.id" target="_blank">{{ record.user.username }}</router-link></div>
              <div class="small" v-if="record.user">({{ record.user.siteUsername }})</div>
            </td>
            <td>
              <partner-badge :partner="record.parent" v-if="record.parent"></partner-badge>

              <span class="badge" :class="{'bg-primary': record.parent.useSeamless === 1, 'bg-success': record.parent.useSeamless === 0}" v-b-tooltip.hover data-placement="top" :title="record.parent.useSeamless === 1 ? '심리스' : '트랜스퍼'" v-html="record.parent.useSeamless === 1 ? 'S':'T'"></span>
              &nbsp;
              <span><router-link :to="'/partner/detail/' + record.parent.id" target="_blank">{{ record.parent.username }}</router-link></span>
            </td>
            <td>{{ record.returnCode }}</td>
            <td>
              <span class="badge bg-warning" v-if="record.action === 'authenticate'">{{ $t('인증') }}</span>
              <span class="badge bg-primary" v-if="record.action === 'bet'">{{ $t('배팅') }}</span>
              <span class="badge bg-success" v-if="record.action === 'result'">{{ $t('배팅결과') }}</span>
              <span class="badge bg-secondary" v-if="record.action === 'cancel'">{{ $t('취소') }}</span>
              <span class="badge bg-pink" v-if="record.action === 'balance'">{{ $t('잔액') }}</span>
              <span class="badge bg-purple" v-if="record.action === 'transfer'">{{ $t('잔액전송') }}</span>
            </td>
            <td>
              <span class="badge bg-danger" v-if="record.result === false">{{ $t('실패') }}</span>
              <span class="badge bg-success" v-if="record.result === true">{{ $t('성공') }}</span>
            </td>
            <td>
              <div class="small" v-if="record.transaction">{{ record.transaction.vendorName }}</div>
              <div class="small" v-if="record.transaction">{{ record.transaction.gameName }}</div>
              <div calss="small">{{ record.message }}</div>
            </td>
            <td>{{ new Date(record.requestAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</td>
            <td>{{ new Date(record.responseAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</td>
            <td>{{ ((new Date(record.responseAt).getTime() - new Date(record.requestAt).getTime())/1000).floatVal(3) }}</td>

          </tr>
          <tr v-show="record.showDetail">
            <th colspan="10">
              <div class="row">
                <div class="col-sm-6 text-start">
                  <div class="bg-dark text-light text-center small p-2">{{ $t('전송 데이터') }} ( {{ record.transactionKey }} )</div>
                  <pre v-highlightjs><code style="max-height: 200px; overflow-y: auto; max-width: 500px;" class="javascript ps-4 pe-4 rounded-bottom">
{{ JSON.stringify(record.sendData, null, ' ') }}
                        </code></pre>
                </div>
                <div class="col-sm-6 text-start">
                  <div class="bg-dark text-light text-center small p-2">{{ $t('수신 데이터') }}</div>
                  <pre v-highlightjs><code style="max-height: 200px; overflow-y: auto; max-width: 500px;" class="javascript ps-4 pe-4 rounded-bottom">
{{ JSON.stringify(record.returnData, null, ' ') }}
                        </code></pre>
                </div>
              </div>
            </th>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
