<template>
  <span class="badge bg-primary me-1" v-b-tooltip.hover data-placement="top" :title="title" v-if="partner && partner.level === 'top'">{{ name }}</span>
  <span class="badge bg-success me-1" v-b-tooltip.hover data-placement="top" :title="title" v-if="partner && partner.level === 'super'">{{ name }}</span>
  <span class="badge bg-info me-1" v-b-tooltip.hover data-placement="top" :title="title" v-if="partner && partner.level === 'master'">{{ name }}</span>
  <span class="badge bg-warning me-1" v-b-tooltip.hover data-placement="top" :title="title" v-if="partner && partner.level === 'agent'">{{ name }}</span>
  <span class="badge bg-dark me-1" v-b-tooltip.hover data-placement="top" :title="title" v-if="partner && partner.level === 'shop'">{{ name }}</span>
  <span class="badge bg-secondary me-1" v-b-tooltip.hover data-placement="top" :title="title" v-if="partner && partner.level === 'user'">{{ name }}</span>
  <span class="badge bg-pink me-1" v-b-tooltip.hover data-placement="top" :title="title" v-if="partner && partner.type === 'admin'">{{ name }}</span>
</template>

<script>
  export default {
    props: {
      partner: { type: Object },
      showName: { type: Boolean, default: false }
    },
    methods: {
      getTitle: function() {
        let result = ''
        if (this.partner.level === 'top') {
          result = this.$t('본사')
        } else if (this.partner.level === 'super') {
          result = this.$t('부본사')
        } else if (this.partner.level === 'master') {
          result = this.$t('지사')
        } else if (this.partner.level === 'agent') {
          result = this.$t('총판')
        } else if (this.partner.level === 'shop') {
          result = this.$t('매장')
        } else if (this.partner.level === 'user') {
          result = this.$t('회원')
        } else if (this.partner.level === 'company') {
          result = this.$t('운영')
        } else if (this.partner.level === 'admin') {
          result = this.$t('운영자')
        } else if (this.partner.level === 'helpdesk') {
          result = this.$t('고객센터')
        }

        return result
      }
    },
    computed: {
      title: function () {
        return this.showName === true ? this.getTitle() : this.partner.username
      },
      name: function () {
        return this.showName === true ? this.partner.username : this.getTitle()
      }
    }
  }
</script>
